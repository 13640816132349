import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentSEO = SEO.find((item) => item.page === "contact");

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contact | ${INFO.main.title}`}</title>
        <meta name="description" content={currentSEO.description} />
        <meta name="keywords" content={currentSEO.keywords.join(", ")} />
      </Helmet>

      <div className="page-content">
        <NavBar active="contact" />
        <div className="content-wrapper">
          <div className="contact-logo-container">
            <div className="contact-logo">
              <Logo width={46} />
            </div>
          </div>

          <div className="contact-container">
            <div className="title contact-title">How To Get In Touch:</div>

            <div className="subtitle contact-subtitle">
              First of all, Thank you for your interest in my portfolio. I
              welcome your feedback, questions, and especially your interest in
              hiring for a work opportunity if you'd like to get in touch. If
              you have a specific question or comment, please feel free to email
              me directly at &nbsp;{" "}
              <a href={`mailto:${INFO.main.email}`}>{INFO.main.email}</a>. I
              make an effort to respond to all messages within 24 hours,
              although it may take me longer during busy periods. Alternatively,
              you can connect with me on my socials if you prefer, I am flexible
              and available on most social media, such as LinkedIn and Instagram
              (see links below).
              <br></br>
              <br></br>I am also available on Telephone Number: +46704226751,
              and for work references, education and CV, please send an email
              and I'll come back to you as soon as possible. Thanks again for
              your interest, and I look forward to hearing from you!
            </div>
          </div>

          <div className="socials-container">
            <div className="contact-socials">
              <Socials />
            </div>
          </div>

          <div className="page-footer">
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
