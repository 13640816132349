const SEO = [
  {
    page: "home",
    description: "",
    keywords: ["Ian Wallenberg"],
  },

  {
    page: "about",
    description: "",
    keywords: ["Ian Wallenberg"],
  },

  {
    page: "articles",
    description: "",
    keywords: ["Ian Wallenberg"],
  },

  {
    page: "projects",
    description: "",
    keywords: ["Ian Wallenberg"],
  },

  {
    page: "contact",
    description: "",
    keywords: ["Ian Wallenberg"],
  },
];

export default SEO;
